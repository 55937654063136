import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth/auth'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    renderKey: 1,
    g_game_state: '',
    show_home: true,
    my_payments: [],
    my_withdrawals: [],
    isWhiteTheme: false,
    group_money: [],
  },

  mutations: {
    set_render_key(state) {
      state.renderKey++
    },

    set_g_game_state(state, gstate) {
      state.g_game_state = gstate
    },

    set_show_home(state, data) {
      state.show_home = data
    },

    SET_MY_PAYMENTS(state, data) {
      state.my_payments = data
    },

    SET_MY_WITHDRAWAL(state, data) {
      state.my_withdrawals = data
    },

    setIsWhiteTheme(state, bool) {
      state.isWhiteTheme = bool
    },

    SET_GROUP_MONEY(state, data) {
      state.group_money = data
    }
  },

  actions: {
    change_render_key({commit}){
      commit('set_render_key')
    },

    change_g_game_state({commit}, gstate) {
      commit('set_g_game_state', gstate)
    },

    set_home_show({commit}, data) {
      commit('set_show_home', data)
    },

    get_my_payments({commit}) {
      return new Promise((resolve, reject) => {
        axios.get('payment/my_payments', {
          headers: {'Authorization': 'Bearer ' + localStorage.getItem('tigpok_token')}
        })
            .then(response => {
              commit('SET_MY_PAYMENTS', response.data)
              resolve(response)
            })
            .catch((err) => {
              reject(err)
            })
      })
    },

    get_my_withdrawals({commit}) {
      return new Promise((resolve, reject) => {
        axios.get('withdrawal/my_withdrawals', {
          headers: {'Authorization': 'Bearer ' + localStorage.getItem('tigpok_token')}
        })
            .then(response => {
              commit('SET_MY_WITHDRAWAL', response.data)
              resolve(response)
            })
            .catch(err => {
              reject(err)
            })
      })
    },

    withdrawal_request({commit}, data) {
      return new Promise((resolve, response) => {
        axios('withdrawal/withdrawal_request', {
          method: 'POST',
          headers: {'Authorization': 'Bearer ' + localStorage.getItem('tigpok_token')},
          data: data
        })
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          reject(err)
        })
      })
    },

    USERS_PUT_PASSWORD({}, passwords) {
      return new Promise((resolve, reject) => {
        axios('users/password', {
          method: 'PUT',
          headers: {'Authorization': 'Bearer ' + localStorage.getItem('tigpok_token')},
          data: passwords
        })
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    USERS_PUT_NICKNAME({}, data) {
      return new Promise((resolve, reject) => {
        axios('users/nickname', {
          method: 'PUT',
          headers: {'Authorization': 'Bearer ' + localStorage.getItem('tigpok_token')},
          data: data
        })
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    GET_GROUP_MONEY({commit}) {
      return new Promise((resolve, reject) => {
        axios.get('users/group_money', {
          headers: {'Authorization': 'Bearer ' + localStorage.getItem('tigpok_token')}
        })
        .then(response => {
          commit('SET_GROUP_MONEY', response.data)
          resolve(response)
        })
      })
    },

    toogleIsWhiteTheme({commit}, isWhite) {
      localStorage.setItem('tigpok_is_white_theme', isWhite)
      console.log(localStorage.getItem('tigpok_is_white_theme'))
      commit('setIsWhiteTheme', isWhite)
    }
  },

  getters: {
    // renderKey: () => state.renderKey,
    g_game_state: (state) => state.g_game_state,
    show_home: (state) => state.show_home,
    MY_PAYMENTS: state => state.my_payments,
    MY_WITHDRAWALS: state => state.my_withdrawals,
    IS_WHITE_THEME: state => state.isWhiteTheme
  },
  modules: {
    auth
  }
})
