<template>
  <div>
    <Bar chart-id="1" :chart-data="chartData" />
  </div>
</template>

<script>
import { Bar } from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "PaymentsFromGroup",
  components: {
    Bar,
  },
  mounted() {
    this.getPaymentsFromGroup();
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            label: 'Coins',
            data: [],
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1
          }
        ]
      }
    } 
  },
  methods: {
    getPaymentsFromGroup() {
      this.$store.dispatch('GET_GROUP_MONEY').then((response) => {
        this.chartData.labels = response.data.map((item) => item.coins);
        this.chartData.datasets[0].data = response.data.map((item) => item.coins);
      });
    },
  },
};
</script>